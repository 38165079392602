@import '@angular/material/prebuilt-themes/deeppurple-amber.css';

/* You can add global styles to this file, and also import other style files */

#containerDiv iframe {
  display: none;
}

body {
  margin: 0;
}
